import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column pe-0 pe-sm-10" }
const _hoisted_2 = { class: "fw-bold text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`alert bg-light-${_ctx.color} border border-${_ctx.color} d-flex flex-column flex-sm-row p-5 mb-10`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1)
    ])
  ], 2))
}